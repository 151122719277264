<script setup lang="ts">

import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import { Link } from '@inertiajs/vue3'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Image } from '@/Pages/the-country/interfaces/City'
import TheImageWebpThumb from '@/Components/the-image/TheImageWebpThumb.vue'
import ComparisonIcon from '@/Components/the-comparison/ComparisonIcon.vue'
import LocationsAtUserWishLists from '@/Components/wishlist/LocationsAtUserWishLists.vue'
import { 
    userWishListLocationsIDs,
    openWishlistModal as wishlistModalOpen 
} from '@/Composables/wishlistLogic'

defineProps<{
    items: {
        id: number
        pic: Image
        title: string
        url: string
        name: string
        cityAlt: string
        rating: number
    }[]
}>()

const swiper = ref()

const openWishlistModal = ():void => {
    wishlistModalOpen()
}

</script>

<template>
    <div v-if="items.length" class="slider-wrapped pt-3">
        <Swiper
            :modules="[Navigation, Pagination]"
            :slides-per-view="2"
            :space-between="16"
            grab-cursor
            navigation
            :centered-slides="false"
            centered-slides-bounds
            watch-overflow
            lazy
            :breakpoints="{ 991: { slidesPerView: 2 }, 768: { slidesPerView: 2 }, 440: { slidesPerView: 1} }"
            :pagination="{ clickable: true, dynamicBullets: true }"
            @swiper="swiper = $event"
        >
            <SwiperSlide v-for="(listing, key) in items" :key class="h-auto px-2">
                <div class="w-100 h-100">
                    <div class="card h-100 border-0 shadow">
                        <div class="card-img-top slp-img-wrap">
                            <TheImageWebpThumb
                                :image="listing.pic"
                                class="img-fluid w-100 slp-img"
                            />

                            <a :href="listing.url" class="tile-link" target="_blank" />

                            <div class="card-img-overlay-top text-right slider-icons-wrap slider-hostels-wrap">
                                <LocationsAtUserWishLists 
                                    :user-wish-list-locations-i-ds="userWishListLocationsIDs" 
                                    :hostel-id="listing.id" 
                                    @open-wishlist-modal="openWishlistModal"
                                />

                                <ComparisonIcon :listing-id="listing.id" />
                            </div>
                        </div>

                        <div class="card-body d-flex align-items-center">
                            <div class="w-100">
                                <h6 class="card-title">
                                    {{ key + 1 }}. <a :href="listing.url" class="text-decoration-none text-dark">{{ listing.name }}</a>
                                </h6>
                                <div class="d-flex card-subtitle mb-3">
                                    <p class="flex-grow-1 mb-0 text-sm">
                                        {{ listing.cityAlt }}
                                    </p>
                                    <div v-if="listing.rating >= 1" class="hostel-card-rating">
                                        <span>{{ listing.rating }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<style lang="scss">

.slider-wrapped .swiper-wrapper,
.slider-wrapped-white .swiper-wrapper {
    padding-bottom: 32px;
}

.slider-wrapped .swiper-pagination .swiper-pagination-bullet {
    opacity: 0.2;
    background: #000;
}

.slider-wrapped .swiper-pagination .swiper-pagination-bullet-active {
    background: #4a5268;
    opacity: 1;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: none;
}

.slp-img-wrap {
    height: 150px;
}

.slp-img {
    height: 100%;
    object-fit: cover;
}

@media (min-width: 768px) {
    .slp-img-wrap {
        height: 230px;
    }
}

.slider-icons-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.slider-wrapped {
    .slider-hostels-wrap {
        .comparison-tooltip {
            display: none;

            @media (min-width: 440px) {
                display: block; 
                right: 110px;
                padding: 10px 20px;
        
                &::before {
                    right: 11%;
                    left: auto;
                }
            }
           
        }

        .locations-at-user-wishlists-tooltip {
            display: none;

            @media (min-width: 440px) {
                display: block; 
                right: 25px;
            
                &::before {
                    left: 53%;
                }
            }
        }
    }
}
</style>